<template>
    <div class="page-print">
        <div class="title-page flex justify-between text-center items-center">
            <div>
                <feather-icon icon="PrinterIcon" svgClasses="h-4 w-4"></feather-icon>
                <h3 class="font-semibold mb-4 ml-3" style="display: inline-block; font-size: 1.125rem;">{{ $t('print') }}</h3>
            </div>
            <div class="flex items-center">
                
            </div>
        </div>
        <div class="line"></div>
        <div>
            <div>
                <feather-icon icon="PackageIcon" svgClasses="h-4 w-4"></feather-icon>
                <h3 class="font-semibold mb-4 ml-3" style="display: inline-block; font-size: 1.125rem;">{{ $t('returnOrders') }}</h3>
            </div>
            <data-table-paper-mission :data="this.$store.state.dataList.data_mission_print1" :header="header" :body="body" v-show="this.data_mission_print1.length != 0" />
            <div class="line"></div>
            <div>
                <feather-icon icon="PackageIcon" svgClasses="h-4 w-4"></feather-icon>
                <h3 class="font-semibold mb-4 ml-3" style="display: inline-block; font-size: 1.125rem;">{{ $t('givenOrders') }}</h3>
            </div>
            <data-table-paper-mission :data="this.$store.state.dataList.data_mission_print2" :header="header" :body="body" v-show="this.data_mission_print2.length != 0" />
            <div class="line"></div>
            <div>
                <feather-icon icon="PackageIcon" svgClasses="h-4 w-4"></feather-icon>
                <h3 class="font-semibold mb-4 ml-3" style="display: inline-block; font-size: 1.125rem;">{{ 'الأوراق المالية' }}</h3>
            </div>
            <div class="line"></div>
            <data-table-paper-mission :data="this.$store.state.dataList.data_mission_print3" :header="headerPaper" :body="bodyPaper" v-show="this.data_mission_print3.length != 0" />
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex"
import { mapGetters } from 'vuex'
import DataTablePaperMission from "../components/table-components/DataTablePaperMission.vue"

export default {
    components: {
        DataTablePaperMission
    },
    data () {
        return {
            print:null,

            header: ['code', 'returnStatus', 'receiver', 'government', 'amountCollect', 'amountSupplied', 'stockValue', 'packagingValue', 'amountSupplied',  'packagingServicePercentage', 'insuranceService'],
            body: ['code', 'inventory_status', 'receiver', 'government', 'received_amount', 'cash_amount', 'shipment_cost', 'stock_service', 'packaging_service', 'packaging_service_percentage', 'protection_service'],
            headerPaper: ['numberPaper', 'type', 'client', 'notes', 'dateOfRegistration'],
            bodyPaper: ['number', 'type', 'client', 'notes', 'created_at']
        }
    },
    computed: {
        ...mapGetters('dataList', ['data_mission_print1', 'data_mission_print2', 'data_mission_print3']),
    },

    methods: {
      ...mapActions('dataList', ['fetchDataMissionPrint']),
    },
    async created () {
            await this.fetchDataMissionPrint(this.$route.params.id)
            this.print = setTimeout(()=> window.print(),500)},
    unmounted(){
        clearTimeout(this.print)}

 
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vuexy/_variables.scss';
.line{
    border-bottom: 0.03125rem solid #70707061;
    opacity: 50%;
    margin-bottom: 1.3rem;
}
</style>
<style lang="scss">
@media print{
    .vx-navbar-wrapper.nav-menu-wrapper{
        display: none !important;
    }
    .navbar-floating .vx-navbar-wrapper{
        display: none !important;
    }
    body table{
        font-size: 12px !important;
    }
    body table tbody td, body table thead th{
        padding: 0px !important;
    }
}
</style>